<template lang="pug">
.facility-tab
  span.section-name {{ currentLocales.metrics }}

  .metrics-cards(v-for="(chunkedList, index) in filteredChunkedMetrics", :key="index")
    .metrics-card(v-for="(element, i) in chunkedList", :key="`${i}_${element.label}`")
      .metrics-card__label.text-weight-bold.q-pb-sm {{ element.label }}
      div {{ metricsCardValue(element) }}
  .metrics-button(v-if="showAllMetricsButton || showHideMetricsButton")
    a(v-if="showAllMetricsButton", href="#", @click.prevent="showingAllMetrics = true") {{ currentLocales.show_all_metrics }}
    a(v-if="showHideMetricsButton", href="#", @click.prevent="showingAllMetrics = false") {{ currentLocales.hide_metrics }}

  .hide-empty-values
    div
      span.section-name {{ currentLocales.real_estate }}
    .hide-empty-values-toggle
      q-toggle(v-model="hideEmptyValues", left-label, :label="currentLocales.hide_empty_fields", color="indigo-12")

  .search
    query-filter(
      @search-all-filter-reset="searchAllFilterReset",
      @update-search-filter="updateSearchFilter",
      :parentData="{ grid, data: [{ label: searchLabel, name: 'query', type: 'search_all' }] }"
    )

  horizontal-filters.horizontal-filters(
    @filterResults="onFilterResults",
    :path="filtersPath",
    :fieldsPathParams="horizontalFiltersFieldsPathParams",
    :activeFilter="activeFilter",
    :iconsByTypes="iconsByTypes"
  )

  template(v-if="!loading")
    .objects_list(v-if="expansionItems.length === 1")
      q-list.expansion-list
        template(v-for="(item, i) in expansionItems[0].items")
          expansion-list-item(
            :key="item.id",
            :element="item",
            :level="1",
            :horizontalFilters="horizontalFiltersContent",
            :ref="el => (expansionItemRefs[i] = el)",
            :showEditButton="true",
            :hideEmptyValues="hideEmptyValues",
            @openEditForm="openEditForm"
          )
    .objects_list(v-else-if="expansionItems.length > 1")
      q-tabs(v-model="currentTab", dense, align="justify", narrow-indicator)
        q-tab(v-for="tab in expansionItems", :name="tab.type", :label="tab.label", :key="tab.template_id")
      q-tab-panels(v-model="currentTab", animated)
        q-tab-panel(
          v-for="(tab, tabIndex) in expansionItems",
          :name="tab.type",
          :key="`${tab.template_id}_${tabIndex}`"
        )
          q-list.expansion-list
            template(v-for="(item, i) in tab.items")
              expansion-list-item(
                :key="item.id",
                :element="item",
                :level="1",
                :horizontalFilters="horizontalFiltersContent",
                :ref="el => (expansionItemRefs[i] = el)",
                :showEditButton="true",
                @openEditForm="openEditForm"
              )

    .objects_list.q-pa-xl.q-ma-xl(v-else)
      .row.justify-center.align-center.card-label
        span {{ locales.notifies[current_locale].no_options_list }}

  .spinner-container.dynamic-facility-spinner(v-else)
    q-spinner(color="primary", size="3em")

  grid-modals(ref="modal", :parentData="{ grid, grid_key, path, transition_key, row, fromDashboard: true }")
</template>

<script setup>
import { ref, onMounted, computed, provide } from "vue";
import { backend } from "@/api";
import { useStore } from "@/store";
import { handleError } from "@/services/handleErrors";
import { locales, currentLocale } from "@/services/useLocales";
import { handleDateRow } from "@/services/reformatStrings";
import queryFilter from "@/components/shared/filters/query_filter.vue";
import _ from "lodash";
import pluralize from "pluralize";

import HorizontalFilters from "@/components/shared/general_components/horizontalFiltersList/HorizontalFilters";
import ExpansionListItem from "@/components/shared/general_components/expansionList/ExpansionListItem";
import GridModals from "@/components/shared/grid/gridModals";

const props = defineProps({
  facilityId: { type: Number, default: -1 },
  id: { type: Number, default: -1 },
});

const emit = defineEmits(["set-title", "loaded"]);

const maxMetricsLinesToPreview = 2;

const query = ref("");

const path = ref(null);
const grid = ref("object_pass");
const grid_key = ref(null);
const transition_key = ref(null);
const callback_params = ref(null);
const row = ref(null);
const modal = ref(null);

const metrics = ref({});
const showingAllMetrics = ref(false);
const activeFilter = ref("");
const horizontalFiltersContent = ref({});
const headerTitleField = ref({});
const expansionItems = ref([]); // tree sctructure data
const expansionItemRefs = ref([]);
const currentTab = ref("");
const hideEmptyValues = ref(true);
const loading = ref(true);

const store = useStore();

const initializeGridsForEdit = () => {
  store.commit("initialGridsState", { grid: "object_pass", attr: {} });
  store.commit("initialGridsState", { grid: "buildings", attr: {} });
  store.commit("initialGridsState", { grid: "floors", attr: {} });
  store.commit("initialGridsState", { grid: "rooms", attr: {} });
  store.commit("initialGridsState", { grid: "users", attr: {} });
  store.commit("initialGridsState", { grid: "companies", attr: {} });
  store.commit("initialGridsState", { grid: "issues", attr: {} });
  store.commit("initialGridsState", { grid: "ppr_equipments", attr: {} });
};

initializeGridsForEdit();

provide("topParentId", metrics.value.id);

const chunkedMetrics = computed(() => {
  const fields = metrics.value?.fields || [];

  return _.chunk(fields, 5);
});

const searchLabel = computed(() => (currentLocale.value === "ru" ? "Поиск" : "Search"));

const filteredChunkedMetrics = computed(() =>
  showingAllMetrics.value ? chunkedMetrics.value : chunkedMetrics.value.slice(0, maxMetricsLinesToPreview),
);
const showAllMetricsButton = computed(
  () => !showingAllMetrics.value && chunkedMetrics.value.length > maxMetricsLinesToPreview,
);
const showHideMetricsButton = computed(
  () => showingAllMetrics.value && chunkedMetrics.value.length > maxMetricsLinesToPreview,
);

const filtersPath = computed(() => `/api/v3/tech_passport/filters?id=${props.id}&template_id=${props.id}`);

const horizontalFiltersFieldsPathParams = computed(() => {
  return {
    main: "api/v3/tech_passport/filters/",
    id: props.id,
    pathOptions: {
      parent_template_id: props.id,
      template_id: "getFromChildComponent::filter.template_id",
    },
  };
});

const iconsByTypes = computed(() => {
  return {
    Building: "apartment",
    Floor: "meeting_room",
    Room: "chair",
    "Ppr::Equipment": "construction",
  };
});

const currentLocales = computed(() => locales.value.facility_dashboard[currentLocale.value].sections);

const loadMetrics = async () => {
  try {
    const response = await backend.index(
      `/api/v3/tech_passport/objects/${props.id}?template_id=${props.id}&filters=${JSON.stringify(
        horizontalFiltersContent.value,
      )}&query=${query.value}`,
    );

    metrics.value = response.data.blocks.find(block => block.type === "description");

    const headerBlockFields = response.data.blocks.find(block => block.type === "header").fields;
    headerTitleField.value = headerBlockFields.find(field => field.label === "title");
  } catch (error) {
    await handleError(error);
  }
};

const searchAllFilterReset = async () => {
  query.value = "";
  store.commit("updateQuery", { grid_name: grid.value, value: query.value });

  closeExpansionList();

  await loadMetrics(); // refetch metrics list
  await loadExpansionItems();
};

const updateSearchFilter = async val => {
  query.value = val;

  closeExpansionList();

  await loadMetrics(); // refetch metrics list
  await loadExpansionItems();
};

const loadExpansionItems = async () => {
  try {
    loading.value = true;
    const response = await backend.index(
      `/api/v3/tech_passport/objects?parent_template_id=${props.id}&parent_id=${props.id}&filters=${JSON.stringify(
        horizontalFiltersContent.value,
      )}&query=${query.value}`,
    );
    expansionItems.value = response.data;
    if (expansionItems.value?.length) currentTab.value = expansionItems.value[0].type;
  } catch (error) {
    await handleError(error);
  } finally {
    loading.value = false;
  }
};

const generateHorizontalFiltersObject = val => {
  if (val?.answers?.length) {
    horizontalFiltersContent.value = {
      type: val.type,
      fields: val.answers.map(elem => {
        if ((elem.type === "dropdown" || elem.type === "radio") && elem.inclusion) {
          return {
            field_id: elem.field_id,
            inclusion: elem.inclusion,
          };
        } else if (elem.type === "integer" && elem.min !== undefined && elem.max !== undefined) {
          return {
            field_id: elem.field_id,
            min: elem.min,
            max: elem.max,
          };
        }
      }),
    };
    activeFilter.value = val.type;
  } else {
    horizontalFiltersContent.value = {};
    activeFilter.value = "";
  }
};

// Close all items of expansion items
const closeExpansionList = () => {
  expansionItemRefs.value.forEach(el => {
    if (el) el.toggleExpansionItem("toggleOnRoot");
  });
};

const ItemType = {
  Building: "building",
  Floor: "floor",
  Room: "room",
};

const getRowData = async (path, id) => {
  try {
    return (await backend.index(`api/v3/${path}/${id}`)).data;
  } catch (e) {
    await handleError(e);
  }
};

const openEditForm = async params => {
  grid.value = params.grid;
  path.value = `/api/v3/${params.path}`;
  transition_key.value = params.report_class;

  if (params.path === "tech_map") {
    grid.value = "ppr_equipments";
    path.value = "/api/v3/ppr/equipments";
    transition_key.value = "ppr_equipment";
    row.value = await getRowData("ppr/equipments", params.id);
  } else {
    row.value = await getRowData(params.path, params.id);
  }

  if (params.grid === "rooms" || params.grid === "companies" || params.grid === "ppr_equipments") {
    row.value = row.value.common.data;
  }

  grid_key.value = "data";

  if (params.additional_params) {
    callback_params.value = params.additional_params;
  }

  if (params.grid === "tech_map") {
    row.value = row.value.common.data;
    row.value.show_tab = "tech_map";
    modal.value.openShow(row.value);
    return;
  }

  modal.value.openForm({ form_type: "edit", row, grid, callback_params });
};

const onFilterResults = async val => {
  generateHorizontalFiltersObject(val); // create object with filters to fetch content (expansion list)
  closeExpansionList();

  await loadMetrics(); // refetch metrics list
  await loadExpansionItems(); // update list of expansions items (tree structure)
};

const isMetricsLineVisible = index => {
  const position = index + 1;
  return position <= maxMetricsLinesToPreview;
};

const metricsCardValue = field => {
  const isMeasureUnitShown = field.measure_unit && field.value;
  const value = isMeasureUnitShown ? `${field.value} ${field.measure_unit}` : field.value;
  if (field.type === "date") {
    return handleDateRow(field, "value");
  }
  if (field.value === 0) {
    return value;
  }
  return value || "-";
};

onMounted(async () => {
  await loadMetrics();
  emit("set-title", headerTitleField.value.value); // set dashboard title
  await loadExpansionItems();
  emit("loaded");
});
</script>

<style scoped lang="scss">
.facility-tab {
  padding-top: 15px;

  .search label {
    margin: 0 0 20px 0 !important;
    width: 50%;
  }

  .dynamic-facility-spinner {
    height: 300px;
    position: static !important;
  }

  .section-name {
    font-weight: 400;
    font-size: 16px;
    color: var(--facility-dashboard-section-name-color);
  }

  .metrics-cards {
    margin-top: 16px;
    display: flex;
    gap: 12px;
    .metrics-card {
      flex-grow: 1;
      padding: 16px 30px;
      background: var(--facility-dashboard-metrics-card-background);
      color: var(--facility-dashboard-metrics-card-color);
      border: 1px solid #dfdfe9;
      border-radius: 20px;
    }

    .metrics-card__label {
      text-transform: capitalize;
    }
  }

  .metrics-button {
    text-align: center;
    padding-top: 10px;

    a {
      color: #5b7aff;
    }
  }

  .horizontal-filters {
    margin-top: 12px;
  }

  .hide-empty-values {
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--facility-dashboard-metrics-card-color);
  }
}
</style>
