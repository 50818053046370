<template lang="pug">
.grid-button-group
  span(v-for="button in buttons")
    q-btn(
      v-if="button.dropdown",
      flat,
      no-caps,
      :style="button.style",
      :id="`${button.name || ''}-${grid.split('_').join('-')}-button`"
    )
      template(v-if="button.icon", slot="default")
        inline-svg.grid-btn-icon-left(:src="require(`../../../assets/icons/header/${button.icon}`)")
      template(v-if="button.label", slot="default")
        span {{ button.label }}
      template(slot="default")
        inline-svg.arrow-down-actions-icon(:src="require(`../../../assets/icons/grid/dropdown.svg`)")

      grid-button-group-menu(
        ref="grid-actions-menu",
        @get-handler="getHandler",
        :parentData="{ grid: grid, grid_key: grid_key, links: button.dropdown }"
      )

    q-btn(
      v-else,
      flat,
      no-caps,
      :style="button.style",
      @click="getHandler(button)",
      :id="`${button.name || ''}-${grid.split('_').join('-')}-button`"
    )
      template(v-if="button.icon", slot="default")
        inline-svg.grid-btn-icon-left(:src="require(`../../../assets/icons/header/${button.icon}`)")
      template(v-if="button.label", slot="default")
        span {{ button.label }}
  q-dialog(v-model="confirmModalShown")
    confirm-modal(@confirm="exportIssuesByFiltersAccepted", :parentData="{ message: notifies.confirm_excel_export }")
</template>

<script>
import { useEmitter } from "@/services/useEmitter";
import gridButtonGroupMenu from "./gridButtonGroupMenu";
import { handleError } from "@/services/handleErrors";

export default {
  components: {
    gridButtonGroupMenu,
    confirmModal: () => import("@/components/shared/helpers/confirmModal"),
  },
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      grid_key: this.parentData.grid_key,
      emitter: useEmitter(),
      confirmModalShown: false,
    };
  },

  computed: {
    buttons() {
      return this.parentData.buttons;
    },
  },

  methods: {
    getHandler(item) {
      return this[item.onclick](item);
    },

    exportIssuesByFilters() {
      this.confirmModalShown = true;
    },

    exportIssuesByFiltersAccepted(val) {
      this.confirmModalShown = false;

      if (val) {
        if (!this.report_id_waiting) {
          const reportPath = "api/v3/dynamic/issues.csv";

          // We need to send request with params for export, so all params taken from store
          const storeFilters = this.$store.state.grid[this.grid].filters;
          const storeQuery = this.$store.state.grid[this.grid].query;
          const deadLineFrom = this.$store.state.grid[this.grid].dateFrom;
          const deadLineTo = this.$store.state.grid[this.grid].dateTo;

          const filters = Object.fromEntries(
            Object.entries(storeFilters).map(([name, content]) => {
              let outputContent = null;

              if (content["value"]) {
                outputContent = content.value;
              } else {
                outputContent = Array.isArray(content) ? content.map(item => item.value) : content;
              }

              return [name, outputContent];
            }),
          );

          const params = {
            facility_id: this.$route.params.facility_id,
            filters,
            dead_line_from: deadLineFrom,
            dead_line_to: deadLineTo,
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          };

          if (storeQuery) {
            params.query = storeQuery;
          }

          return this.$backend
            .index(reportPath, { params }, { encodeNestedData: true })
            .then(({ data }) => {
              this.$root.$emit("init-report-intervals", data);
              this.$q.notify(this.notifies.report_in_process);
            })
            .catch(async error => {
              await handleError(error);
            })
            .finally(() => {
              this.loading = false;
              this.$emit("close-export", {});
            });
        } else {
          this.loading = false;
          this.$emit("close-export", {});
          this.$q.notify(this.notifies.wait_report_generated);
        }
      }
    },

    addNewItem() {
      this.$root.$emit("add-new-item");
      this.emitter.emit("add-new-item"); // for composition api
    },

    openExportForm(item) {
      this.$root.$emit("open-export-form", item);
      this.emitter.emit("open-export-form", item); // for composition api
    },

    openImportForm(item) {
      this.$root.$emit("open-import-form", item);
    },

    openVerifyCheckinsForm(item) {
      this.$root.$emit("verify-checkins-form", item);
    },

    openPrintQRListForm(item) {
      this.emitter.emit("print-qr-list-form", item);
    },
  },
};
</script>
