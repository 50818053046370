<template lang="pug">
q-menu#grid-button-menu.grid-button-menu(ref="grid-button-menu")
  q-list
    q-item(
      v-for="link in links",
      :key="link.label",
      @click="getHandler(link)",
      v-close-popup="1",
      clickable,
      :disable="isButtonDisabled(link)"
    )
      q-item-section
        q-item-label {{ link.label }}
</template>

<script>
export default {
  components: {},

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      grid_key: this.parentData.grid_key,
    };
  },

  computed: {
    links() {
      return this.parentData.links;
    },
  },

  created() {
    setTimeout(() => {
      let obj = document.getElementById("main");
      if (obj) {
        obj.addEventListener("scroll", this.closeMenu, false);
      }
    }, 500);
  },

  destroyed() {
    setTimeout(() => {
      let obj = document.getElementById("main");
      if (obj) {
        obj.removeEventListener("scroll", this.closeMenu, false);
      }
    }, 500);
  },

  methods: {
    getHandler(link) {
      this.$emit("get-handler", link);
    },

    closeMenu() {
      if (this.$refs["grid-button-menu"]) {
        this.$refs["grid-button-menu"].hide();
      }
    },

    isButtonDisabled(link) {
      return !((link.hasOwnProperty("enabled") && link.enabled) || !link.hasOwnProperty("enabled"));
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/menu/grid_actions_menu";
</style>
