import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
// import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export const grid = {
  state: {},

  mutations: {
    // GRID STATE INITIAL

    initialGridsState(state, data) {
      let initial_fields = {
        form: {},
        query: "",
        filters: {},
        except_filters: {},
        actions_by_checkbox: {
          by_row: [],
          actions: [],
        },
      };

      if (data.attr) {
        let keys = Object.keys(data.attr);
        keys.forEach(key => {
          initial_fields[key] = data.attr[key];
        });
      }

      Vue.set(state, data.grid, initial_fields);
    },

    // GRIDS AND FILTERS MIGRATIONS

    initialGrid(state, data) {
      let grid = {
        actions: {},
        columns: [],
        data: [],
        count: 0,
        pagination: {
          sortBy: data.sort_by || "id",
          descending: data.descending,
          page: 1,
          rowsPerPage: 0,
          rowsNumber: 0,
        },
      };
      Vue.set(state[data.grid_name], data.grid_key, grid);
    },

    updateGridData(state, result) {
      if (result.grid_name !== "ppr_calendar") {
        const grid = state[result.grid_name][result.grid_key];
        const current_page = grid.pagination.page;
        const next_page = result.grid_data.pagination.page;
        if (current_page !== next_page && current_page <= next_page && grid.data.length > 0) {
          result.grid_data.data = grid.data.concat(result.grid_data.data);
        }
        Vue.set(state[result.grid_name], result.grid_key, result.grid_data);
      } else {
        Vue.set(state[result.grid_name][result.grid_key], result.grid_key, result.grid_data);
      }
    },

    updateQuery(state, data) {
      Vue.set(state[data.grid_name], "query", data.value);
    },

    resetQuery(state, data) {
      Vue.delete(state[data.grid_name], "query");
    },

    updateFilter(state, data) {
      Vue.set(state[data.grid_name]["filters"], data.filter, data.value);
    },
    updateDateFromDynamicFilter(state, data) {
      state[data.grid_name]["dateFrom"] = data.value;
    },

    updateDateToDynamicFilter(state, data) {
      state[data.grid_name]["dateTo"] = data.value;
    },

    resetFilter(state, data) {
      Vue.delete(state[data.grid_name]["filters"], data.filter);
    },

    removeAllFilters(state, data) {
      Vue.set(state[data.grid_name], "filters", {});
    },

    updateActionsByCheckbox(state, data) {
      let obj = [];
      let current = state[data.grid_name]["actions_by_checkbox"]["by_row"];

      if (data.value) {
        obj = current.find(o => o.row.id === data.value.row.id);

        if (obj) {
          Vue.delete(state[data.grid_name]["actions_by_checkbox"]["by_row"], current.indexOf(obj));
        } else {
          current.push(data.value);
        }
      } else {
        current.splice(0);
      }

      let actions = _.intersectionBy(...current.map(obj => obj.actions_by_checkbox), "key");

      Vue.set(state[data.grid_name]["actions_by_checkbox"], "actions", actions);
    },

    updateActions(state, data) {
      Vue.set(state[data.grid_name][data.grid_key], "actions", data.actions);
    },

    resetAllFilters(state, data) {
      data.filters.forEach(filter => {
        Vue.delete(state[data.grid_name]["filters"], filter);
      });
    },

    // FORM MIGRATIONS
    createFormField(state, data) {
      Vue.set(state[data.grid_name]["form"], data.field);
    },

    updateFormField(state, data) {
      Vue.set(state[data.grid_name]["form"], data.field, _.cloneDeep(data.value));
    },

    updateSelectedItemsFormField(state, data) {
      Vue.set(state[data.grid_name]["form"][data.field], "selected_items", data.value);
    },

    resetFormField(state, data) {
      Vue.delete(state[data.grid_name]["form"], data.field);
    },

    resetFormFieldValue(state, data) {
      let form_field = state[data.grid_name]["form"][data.field];

      if (form_field) {
        Vue.delete(state[data.grid_name]["form"][data.field], data.value);
      }
    },

    resetFormAllFields(state, data) {
      data.fields.forEach(field => {
        Vue.delete(state[data.grid_name]["form"], field);
      });
    },

    // resetGridForm(state, grid_name) {
    //   let grid = state[grid_name]
    //   grid.form = {}
    //
    //   Vue.set(state, grid_name, grid)
    // },

    // PPR EQUIPMENT TECH MAP MIGRATIONS
    initialEquipmentTechMap(state, data) {
      const attr = {
        components: [],
      };
      Vue.set(state[data.grid_name], "tech_map", attr);
    },

    initialStoringMaterials(state, data) {
      const attr = {
        components: [],
      };
      Vue.set(state[data.grid_name], "materials_list", attr);
    },

    initialConsumptionsHistory(state, data) {
      const attr = {
        components: [],
      };
      Vue.set(state[data.grid_name], "consumptions_history", attr);
    },

    initialStoringWorks(state, data) {
      const attr = {
        components: [],
      };
      Vue.set(state[data.grid_name], "works_list", attr);
    },

    addEquipmentTechMap(state, data) {
      Vue.set(state[data.grid_name]["tech_map"], data.tech_map_key, data.attr);
    },

    addStoringMaterials(state, data) {
      Vue.set(state[data.grid_name]["materials_list"], data.tech_map_key, data.attr);
    },

    addConsumptionsHistory(state, data) {
      Vue.set(state[data.grid_name]["consumptions_history"], data.tech_map_key, data.attr);
    },

    addWorkingGroupWorks(state, data) {
      Vue.set(state[data.grid_name]["works_list"], data.tech_map_key, data.attr);
    },

    updateEquipmentTechMap(state, data) {
      if (data.attr.value_key_parent) {
        Vue.set(
          state[data.grid_name]["tech_map"][data.tech_map_key][data.attr.index][data.attr.value_key_parent][
            data.attr.sub_index
          ],
          data.attr.value_key,
          data.attr.value,
        );
      } else {
        Vue.set(
          state[data.grid_name]["tech_map"][data.tech_map_key][data.attr.index],
          data.attr.value_key,
          data.attr.value,
        );
      }
    },
    updateMaterialsList(state, data) {
      if (data.attr.value_key_parent) {
        Vue.set(
          state[data.grid_name]["materials_list"][data.tech_map_key][data.attr.index][data.attr.value_key_parent][
            data.attr.sub_index
          ],
          data.attr.value_key,
          data.attr.value,
        );
      } else {
        Vue.set(
          state[data.grid_name]["materials_list"][data.tech_map_key][data.attr.index],
          data.attr.value_key,
          data.attr.value,
        );
      }
    },
    updateConsumptionsHistory(state, data) {
      if (data.attr.value_key_parent) {
        Vue.set(
          state[data.grid_name]["consumptions_history"][data.tech_map_key][data.attr.index][data.attr.value_key_parent][
            data.attr.sub_index
          ],
          data.attr.value_key,
          data.attr.value,
        );
      } else {
        Vue.set(
          state[data.grid_name]["consumptions_history"][data.tech_map_key][data.attr.index],
          data.attr.value_key,
          data.attr.value,
        );
      }
    },

    updateWorksList(state, data) {
      if (data.attr.value_key_parent) {
        Vue.set(
          state[data.grid_name]["works_list"][data.tech_map_key][data.attr.index][data.attr.value_key_parent][
            data.attr.sub_index
          ],
          data.attr.value_key,
          data.attr.value,
        );
      } else {
        Vue.set(
          state[data.grid_name]["works_list"][data.tech_map_key][data.attr.index],
          data.attr.value_key,
          data.attr.value,
        );
      }
    },

    setIssueActionsInIssueCenter(state, data) {
      Vue.set(state["issue_center"], "actions", data);
    },

    setIssueActionsInDynamicIssues(state, data) {
      Vue.set(state["dynamic_issues"], "actions", data);
    },

    setDynamicIssueFilters(state, data) {
      Vue.set(state["dynamic_issues"], "filters", data);
    },

    setDynamicIssueQuery(state, data) {
      Vue.set(state["dynamic_issues"], "query", data);
    },
  },

  actions: {},
};
