<template lang="pug">
.filtered-notification(v-if="showCounts")
  .filtered-notification__used-filter {{ selectedFilterText }}
  .filtered-notification__filtered {{ text }}
</template>

<script setup>
import { computed } from "vue";
import { dynamicIssuesLocales } from "@/services/useLocales";

const props = defineProps({
  /* 
  Показываем ли числа фильтров (настраивается с бека)
  */
  showCounts: { type: Boolean, default: false },
  /* 
  Проп содержащий объект примененного фильтра. { label: Text }
  Отвечает за текст примененного фильтра selectedFilterText
  */
  selectedFilter: { type: Object, default: () => {}, required: false },
  /* 
  Проп содержащий количество отфильтрованного { filtered: Number, total: Number }
  */
  counts: { type: Object, default: () => {}, required: false },
});

const currentLocales = computed(() => dynamicIssuesLocales.value);

const text = computed(() =>
  props.counts.total !== props.counts.filtered
    ? `${currentLocales.value["counts"].filtered} ${props.counts.filtered} ${currentLocales.value["counts"].from} ${props.counts.total}`
    : `${currentLocales.value["counts"].total}: ${props.counts.total}`,
);

const selectedFilterText = computed(() => {
  // форматирует текст и добавляет имя примененного фильтра

  if (props.selectedFilter?.value) {
    const appliedFilter = currentLocales.value["filters"].applied_filter;
    const filterName = props.selectedFilter.label;

    const filterNameFirstLetterUppercase = filterName.charAt(0).toUpperCase() + filterName.slice(1); // первая буква большая, остальные маленькие

    return `${appliedFilter}: ${filterNameFirstLetterUppercase}`;
  } else {
    return "";
  }
});
</script>

<style scoped lang="scss">
.filtered-notification {
  display: flex;
  justify-content: space-between;

  &__filtered {
    color: #b5bbc9;
    text-transform: lowercase;

    &::first-letter {
      text-transform: capitalize;
    }
  }
  &__used-filter {
    color: #b5bbc9;

    &::first-letter {
      text-transform: capitalize;
    }
  }
}
</style>
