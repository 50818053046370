import { render, staticRenderFns } from "./FilteredNotification.vue?vue&type=template&id=30155007&scoped=true&lang=pug&"
import script from "./FilteredNotification.vue?vue&type=script&setup=true&lang=js&"
export * from "./FilteredNotification.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./FilteredNotification.vue?vue&type=style&index=0&id=30155007&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30155007",
  null
  
)

export default component.exports