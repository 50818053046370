<template lang="pug">
.expansion-item(v-if="!loading", :class="getExpansionItemClass()")
  .expansion-item__head
    .expansion-item__head__title(
      v-for="column in columns",
      :key="column.name + values.id",
      :style="getExpansionItemHeadTitleStyle(column)"
    )
      .primary(v-if="column.name === 'title'")
        inline-svg.expansion-item__head__building-icon(
          :src="require(`../../../../assets/icons/object_pass/${levelName}.svg`)",
          :style="getExpansionItemHeadBuildingIconStyle()"
        )
        .header
          .header__main {{ values.title }}
          .header__secondary {{ values.subtitle }}
          .header__secondary {{ `№ ${values.id}` }}
      .expansion-item__head__title(v-else) {{ getTitleValue(values[column.name]) }}
    .expansion-item__head__expand(v-if="nextLevel")
      Transition(name="rotate", mode="out-in")
        inline-svg.expand-icon(:src="openIcon", @click="changeOpen", :key="`${isOpen}`")
    .expansion-item__head__expand-plug(v-else)
  .expansion-item__head-sep(v-if="headerSepDisplay", :style="getExpansionItemSepStyle()")

  TransitionGroup(v-if="isOpen", name="slide")
    .expansion-item__tabs(v-if="nextLevel === 'equipments'", :key="`content-expansion-tabs-${isOpen}`")
      expansion-row-item-tabs(:level="level", :filter="getFilters()", grid="expensesDashboard")
    .expansion-item__expansion(v-else-if="nextLevel !== 'issues'", :key="`content-expansion-${level}-${isOpen}`")
      .expansion-item__sep(:style="getExpansionItemSepStyle()")
      expansion-rows(
        :level="level + 1",
        query,
        :path="nextLevelPath",
        :loadColumns="false",
        :nextLevelFilter="getFilters()",
        :nextLevel="nextLevel",
        :levelName="nextLevel"
      )
    .expansion-item__content(v-else, :key="`content-issues-${level}-${isOpen}`", :class="`level-${level}`")
      expansion-row-item-content(:filter="getFilters()", :level="level")
</template>

<script setup>
import { ref, computed, toRefs } from "vue";

import expansionRows from "./ExpansionRows.vue";
import ExpansionRowItemContent from "./ExpansionRowItemContent.vue";
import ExpansionRowItemTabs from "./ExpansionRowItemTabs.vue";

import DownArrow from "@/assets/icons/grid/down_arrow.svg";
import UpArrow from "@/assets/icons/grid/up_arrow.svg";

const props = defineProps({
  /**
   * Данные row для columns для дальнейшего сопоставления колонок с их данными. Приходят в формате: `{ id: 0, type: "building_id" ... }`.
   * Данные могут быть разные для каждого последующего уровня. Например для уровня equipments values отличается от остальных
   * Пример: 
      `{
        completed_works_expenses: "76 795";
        consumptions_expenses: "78 950";
        id: 93;
        issues_count: "38";
        issues_expenses: "130 331";
        next_level: "issues";
        ppr_issues_count: "10";
        ppr_issues_expenses: "25 414";
        subtitle: "г Москва, ул Академика Миллионщикова, д 16";
        title: "ТРЦ Эталон";
        total_expenses: "155 745";
        total_issues_count: "48";
        type: "building_id";
      `}
   */
  values: {
    type: Object,
    default: () => {},
  },
  /**
   * Columns для row. Колонки в которые подставляются данные из values. Колонки приходят вместе с размером
   * Приходят в формате: `[ { label: "Объект", name: "title", width: "22%" }, ...]`.
   * Данные могут быть разные для каждого последующего уровня. Например для уровня equipments columns отличается от остальных
   * Пример: 
      `[
        {
            "name": "title",
            "label": "Объект",
            "width": "22%"
        },
        {
            "name": "issues_count",
            "label": "Сервисные заявки, шт",
            "width": "9%"
        },
        {
            "name": "issues_expenses",
            "label": "Сервисные затраты, ₽",
            "width": "9%"
        },
        {
            "name": "ppr_issues_count",
            "label": "Заявки ППР, шт",
            "width": "6%"
        },
        {
            "name": "ppr_issues_expenses",
            "label": "Затраты ППР, ₽",
            "width": "6%"
        },
        {
            "name": "completed_works_expenses",
            "label": "Затраты работы, ₽",
            "width": "8%"
        },
        {
            "name": "consumptions_expenses",
            "label": "Затраты материалы, ₽",
            "width": "10%"
        },
        {
            "name": "total_issues_count",
            "label": "Всего заявок, шт",
            "width": "9%"
        },
        {
            "name": "total_expenses",
            "label": "Общие затраты, ₽",
            "width": "9%"
        }
      `]
   */
  columns: {
    type: Array,
    default: () => [],
  },
  /**
   * На каком уровне сейчас находимся. Увеличивается на 1 за каждое углубление в экспансию
   * Пример: `0`
   */
  level: {
    type: Number,
    default: () => 0,
  },
  /**
   * Значение в фильтре поиска
   * Пример: `"abcd"`
   */
  query: {
    type: String,
    default: () => "",
  },
  /**
   * Булеан значение является ли текущий row последним
   * Пример: `false`
   */
  lastItem: {
    type: Boolean,
    default: () => false,
  },
  /**
   * Значение текущего уровня в экспансии. Нужно для получения иконок в заголовках row
   * Пример: `"Objects"`
   */
  levelName: {
    type: String,
    required: false,
    default: () => "Objects",
  },
});

const marginByLevel = 16;

const isOpen = ref(false);
const loading = ref(false);
const { values } = toRefs(props);
const { columns } = toRefs(props);
const { level } = toRefs(props);

const openIcon = computed(() => (isOpen.value ? UpArrow : DownArrow));
const nextLevel = computed(() => values.value.next_level);
const nextLevelPath = computed(() => `/api/v3/expenses_dashboard/${nextLevel.value}`);
const headerSepDisplay = computed(() => props.level > 0 && !isOpen.value && !props.lastItem);

const isIssuesEmpty = values => {
  return Number(values.issues_count) === 0 && Number(values.ppr_issues_count) === 0;
};

const getColumnPaddingLeft = column => {
  return column.name === "title" && level.value > 0 ? level.value * marginByLevel : 0;
};

const changeOpen = () => {
  isOpen.value = !isOpen.value;
};

const getFilters = () => {
  return {
    type: props.values.type,
    value: [props.values.id],
  };
};

const getTitleValue = val => {
  const parseDate = new Date(val);
  if (parseDate.toString() !== "Invalid Date" && parseDate.toISOString() === val) {
    return `${parseDate.toLocaleDateString("ru")}`;
  }
  return val;
};

const getIconHeightByName = name => {
  const objecIcontHeight = 74,
    groupSystemIconHeight = 82,
    systemsIconHeight = 64,
    equipmentsIconHeight = 52,
    defaultIconHeight = 80;
  if (name === "objects") return objecIcontHeight;
  else if (name === "group_systems") return groupSystemIconHeight;
  else if (name === "systems") return systemsIconHeight;
  else if (name === "equipments") return equipmentsIconHeight;
  else return defaultIconHeight;
};

const getExpansionItemClass = () => {
  const classes = [`level-${level.value}`];
  if (isOpen.value) {
    classes.push("opened");
  }
  return classes.join(" ");
};

const getExpansionItemHeadTitleStyle = column => {
  const style = {
    width: column.width,
    paddingLeft: `${getColumnPaddingLeft(column)}px`,
  };
  return style;
};

const getExpansionItemHeadBuildingIconStyle = () => {
  const style = {
    height: `${getIconHeightByName(props.levelName)}px`,
    margin: props.levelName === "objects" ? "21px 12px" : "12px",
  };
  return style;
};

const getExpansionItemSepStyle = () => {
  const style = {
    marginLeft: `${(props.level + 1) * marginByLevel}px`,
    width: `calc(100% - ${(props.level + 1) * marginByLevel}px)`,
  };
  return style;
};
</script>

<style lang="scss" scoped>
.expansion-item {
  border-radius: 24px;
  background-color: var(--expenses-content-background-color);
  position: relative;

  &__head {
    padding: 16px;
    display: flex;
    gap: 16px;

    &__title,
    &__expand {
      display: flex;
      align-items: center;
    }

    &__expand {
      width: 2%;

      &-plug {
        width: 2%;
      }
    }

    :deep(.expand-icon) {
      width: 20px;
      height: 20px;
      cursor: pointer;

      path {
        fill: var(--expenses-main-text-color);
      }
    }

    &__title {
      color: var(--expenses-expansion-text-color);
    }

    .primary {
      width: 100%;
      border-radius: 16px;
      background-color: var(--expenses-expansion-primary-block-background-color);
      display: flex;
      align-items: center;
      gap: 12px;
      padding-right: 15px;

      .header {
        height: 100%;
        width: calc(100% - 64px - 12px);
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__main {
          font-size: 12x;
          color: #5b7aff;
          font-weight: 600;
        }

        &__secondary {
          font-size: 12px;
          color: var(--expenses-main-text-color);
        }
      }
    }

    &-sep {
      height: 1px;
      background-color: var(--expenses-search-button-border-color);
    }
  }

  &__content {
    max-height: 392px;
    overflow-y: auto;
    padding: 0 16px 16px;
    position: relative;

    &__children--no-data {
      display: flex;
      justify-content: center;
      font-style: italic;
    }

    @for $i from 0 through 3 {
      &.level-#{$i} {
        &::after {
          content: "";
          width: calc(100% - ($i * 16px));
          height: 2px;
          position: absolute;
          top: 0;
          right: 0;
          background-color: var(--expenses-expansion-separator-color);
        }
      }
    }
  }

  &__sep {
    height: 2px;
    background-color: var(--expenses-search-button-border-color);
  }

  // стиль применяется для класса level-#{$i}, где i номер уровня экспансии. Начиная от 2 и до 4
  @for $i from 1 through 3 {
    &.level-#{$i} {
      &::before {
        content: "";
        position: absolute;
        width: 2px;
        top: 0;
        left: $i * 16px;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(#5b7aff 100%, rgba(255, 255, 255, 0) 0%);
        background-position-x: left;
        background-position-y: bottom 25px;
        background-size: 2px 2px;
        background-repeat: repeat-y;
        z-index: 1;
      }

      .expansion-item__head {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: calc(50% - 5px);
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 2px solid #5b7aff;
          background-color: var(--expansion-list-background);
          z-index: 2;
        }
      }

      &:last-child {
        &::before {
          display: none;
        }

        .expansion-item__head {
          &::after {
            content: "";
            position: absolute;
            width: 2px;
            top: 0;
            left: $i * 16px;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(#5b7aff 100%, rgba(255, 255, 255, 0) 0%);
            background-position-x: left;
            background-position-y: bottom 25px;
            background-size: 2px 2px;
            background-repeat: repeat-y;
            z-index: 1;
            height: calc(50% - 5px);
          }
        }
      }
    }
  }

  &.level-0 {
    .tab-rows {
      margin-top: 0;
    }
  }

  &.level-1 {
    .expansion-item__head {
      &::before {
        left: 12px;
      }
    }
  }
  &.level-2 {
    .expansion-item__head {
      &::before {
        left: 28px;
      }
    }
  }
}

:deep(.expansion-item__head__building-icon) {
  width: auto;
  height: 64px;
  margin: 12px;
}

.rotate-enter-active,
.rotate-leave-active {
  transition: transform 0.3s ease;
}

.rotate-enter-from,
.rotate-leave-to {
  transform: rotate(0.5turn);
}

:deep(.q-toggle__inner--truthy) {
  .q-toggle__thumb:after {
    color: #5b7aff;
  }
  .q-toggle__track {
    color: #adbdff;
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: transform 0.3s ease;
}
.slide-enter {
  transform: translateY(100%);
}
.slide-leave-to {
  transform: translateY(100%);
}
</style>
