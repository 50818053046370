<template lang="pug">
q-input.search-all-filter-field(filled, v-model="currentFilter", debounce="500", :label="label", @focus="onInputFocus")
  q-icon.cancel-search-all-filter(name="cancel", v-if="currentFilter", @click.stop="resetFilter()")
</template>

<script>
import { useEmitter } from "@/services/useEmitter";

export default {
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      emitter: useEmitter(),
    };
  },

  computed: {
    label() {
      return this.parentData.data[0].label;
    },

    name() {
      return this.parentData.data[0].name;
    },

    currentFilter: {
      get() {
        return this.$store.state.grid[this.grid][this.name];
      },

      set(value) {
        this.$store.commit("updateQuery", { grid_name: this.grid, value });
        this.emitter.emit("search-all-filter-updated", true);
        this.$emit("update-search-filter", value);
      },
    },
  },

  methods: {
    resetFilter() {
      this.$emit("search-all-filter-reset");
    },

    onInputFocus() {
      this.$emit("focus");
    },
  },
};
</script>

<style scoped lang="scss">
// @import "../../../assets/styles/filters/search-all";

.search-all-filter-field {
  width: 100%;
  max-width: 80vw;
  float: left;
  border-radius: 5px;

  :deep(.q-field__inner) {
    height: 45px !important;

    .q-field__control {
      height: 45px !important;
      border-radius: 8px;
      background-color: var(--field-background);
      border: var(--field-border);

      .q-field__label {
        color: var(--field-label-color);
        margin-top: -5px;
        font-size: 15px;
      }

      input {
        color: var(--field-input-color);
        margin-top: -5px;
      }
    }
  }

  .cancel-search-all-filter {
    position: absolute;
    right: 5px;
    margin-top: 16px;
    cursor: pointer;
    color: var(--field-cancel-icon-color);
  }
}
</style>
